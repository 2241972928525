<template>
  <div>
    <div class="loader" v-if="isSubmitting"></div>
    <div v-if="!isSubmitting">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div>
            <label class="required-field">Title</label>
          </div>
          <CInput v-model="form.title" type="text" placeholder="Enter property title" />
          <span v-if="$v.form.title.$error" class="text-danger">Title is required</span>
        </div>
        <div class="col-12 col-sm-6">
          <label class="required-field">Location</label>
          <!-- <CInput v-model.trim="form.location" placeholder="Enter your location" /> -->
          <autocomplete1
            @getLocation="getLocation"
            @isLocationError="isLocationError = true"
            :locationData="form.location"
            :isFromLanding="true"
            :isReadonly="false"
            fromFront="0"
          />
          <span v-if="this.isLocationError" class="text-danger"
            >Please enter valid location</span
          >
          <span v-if="$v.form.location.$error" class="text-danger"
            >Location is required</span
          >
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-12 col-sm-6">
                <div>
                    <label class="required-field">Max Capacity</label>
                </div>
                <CInput
                    v-model="form.capacity"
                    type="number"
                    class="validate_number"
                    min="0"
                    max="500"
                    placeholder="Enter Capacity"
                />
                <span v-if="!$v.form.capacity.required" class="text-danger"
                    >Max Capacity is required</span
                >
                <span v-if="!$v.form.capacity.maxValue" class="text-danger"
                    >Max Capacity should be less than 500</span>
          </div>
      </div> -->
      <!-- <div class="row">
            <div class="col-12 col-sm-6">
                <div>
                    <label class="required-field">Max Capacity</label>
                </div>
                <CInput
                    v-model="form.capacity"
                    type="number"
                    class="validate_number"
                    min="0"
                    max="500"
                    placeholder="Enter Capacity"
                />
                <span v-if="!$v.form.capacity.required" class="text-danger"
                    >Max Capacity is required</span
                >
                <span v-if="!$v.form.capacity.maxValue" class="text-danger"
                    >Max Capacity should be less than 500</span
                >
            </div>

            <div class="col-12 col-sm-6">
                <div>
                    <label class="required-field">Price(per person in $)</label>
                </div>
                <CInput
                    v-model="form.price"
                    type="number"
                    class="validate_number"
                    min="0"
                    placeholder="Enter Price"
                />
                <span v-if="$v.form.price.$error" class="text-danger"
                    >Price is required</span
                >
            </div>
        </div> -->
      <div class="row">
        <div class="col-12 col-sm-6">
          <div>
            <label>Building Height (e.g: 2 Stories)</label>
          </div>
          <CInput
            v-model="form.building_height"
            type="text"
            placeholder="Enter building floors like (2 Stories)"
          />
        </div>

        <div class="col-12 col-sm-6">
          <div>
            <label>Building Size(in Sq ft)</label>
          </div>
          <CInput
            v-model="form.building_size"
            min="0"
            class="validate_number"
            type="number"
            placeholder="Enter your building size"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <div>
            <label>Typical Floor Size(in Sq ft)</label>
          </div>
          <CInput
            v-model="form.area"
            min="0"
            class="validate_number"
            type="number"
            placeholder="Enter your area"
          />
          <!-- <span v-if="$v.form.area.$error" class="text-danger"
                    >Area is required</span
                > -->
        </div>
        <div class="col-12 col-sm-3">
          <div>
            <label>Country</label>
          </div>
          <CInput
            v-model="form.country"
            readonly
            type="text"
            placeholder="Country Name"
          />
        </div>
        <div class="col-12 col-sm-3">
          <div>
            <label>Zip Code</label>
          </div>
          <CInput v-model="form.pincode" readonly type="number" placeholder="Zip Code" />
        </div>

        <!-- <div class="col-12 col-sm-6">
          <div>
            <label>Class Type</label>
          </div>
          <CInput
            v-model="form.property_class"
            type="text"
            placeholder="Enter Class Type"
          />
        </div> -->
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <div>
            <label>Floors</label>
          </div>
          <CInput
            v-model="form.floors"
            type="number"
            class="validate_number"
            placeholder="Enter floors"
          />
          <!-- <span v-if="$v.form.floors.$error" class="text-danger"
                    >Floors is required</span
                > -->
        </div>
        <div class="col-12 col-sm-3">
          <div>
            <label class="required-field">Available From</label>
          </div>
          <CInput
            v-model="form.available_from"
            type="date"
            placeholder="Enter availble from"
          />
          <span v-if="$v.form.available_from.$error" class="text-danger"
            >Availability is required</span
          >
        </div>
        <div class="col-12 col-sm-3">
          <div>
            <label>End Date </label>
          </div>
          <CInput
            v-model="form.available_end"
            type="date"
            placeholder="Enter availble end"
          />
          <!-- <span v-if="$v.form.available_end.$error" class="text-danger">Availability is required</span> -->
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <!-- <div>
            <label>Class Type (e.g: A / B / C)</label>
          </div>
          <CInput
            v-model="form.property_class"
            type="text"
            placeholder="Enter Class Type"
          /> -->

          <div class="">
            <label class="required-field">Building Class Type</label>
          </div>
          <select class="form-control" v-model="form.property_class">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
        </div>
        <div class="col-12 col-sm-6">
          <div class="pb-2">Amenities</div>
          <multiselect
            @select="onSelectAmenity"
            label="name"
            track-by="id"
            :multiple="true"
            v-model="Avalue"
            @remove="removeAmenities"
            :options="Amenities"
          ></multiselect>
          <!-- <span v-if="$v.form.selectedAmenity.$error" class="text-danger"
                    >Amenities is required</span
                > -->
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <div>
            <label class="required-field">Max Capacity</label>
          </div>
          <CInput
            v-model="form.capacity"
            type="number"
            class="validate_number"
            min="0"
            max="500"
            placeholder="Enter Capacity"
          />
          <span v-if="!$v.form.capacity.required" class="text-danger"
            >Max Capacity is required</span
          >
          <span v-if="!$v.form.capacity.maxValue" class="text-danger"
            >Max Capacity should be less than 500</span
          >
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Description</label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              v-model="form.property_description"
              placeholder="Enter property description"
              rows="3"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div>
            <label>Property Video</label>
          </div>
          <div>
            <input
              type="radio"
              v-model="property_video_radio"
              class="mr-1"
              id="property_video_upload"
              :value="true"
            />
            <label for="property_video_upload">Video Upload</label>
            <input
              type="radio"
              v-model="property_video_radio"
              id="property_video_url"
              class="ml-5 mr-1"
              :value="false"
            />
            <label for="property_video_url">Video URL</label>
          </div>
          <div v-if="property_video_radio">
            <input
              id="PropertyVideo"
              ref="PropertyVideo"
              name="PropertyVideo"
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              @change="uploadPropertyVideo"
            />
            <a v-if="form.property_video" :href="form.video_link.url" target="_blank"
              >Link</a
            >
            <br />
            <span>Max upload size limit : 20MB</span>
            <br />
            <p>
              <span v-if="propertyVideoErrorMessage" class="text-danger">{{
                propertyVideoErrorMessage
              }}</span>
            </p>
          </div>
          <div v-else>
            <CInput
              v-model="property_video_url"
              type="url"
              placeholder="Enter the Video URL here"
            />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <label class="c-switch c-switch-pill c-switch-primary">
            Publish the property
            <input type="checkbox" class="c-switch-input" v-model="form.is_available" />
            <span class="c-switch-slider"></span>
            <p style="color: #1f90ff">
              Use this toggle to set the property as available for lease.
            </p>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div
            @click="openSection = !openSection"
            class="btn btn-dark btn-block dropdown-toggle mt-4 mb-3 d-flex justify-content-between align-items-center"
          >
            Add Nearby Amenities
          </div>

          <div v-if="openSection" class="row">
            <div
              class="col-6 d-flex flex-column flex-lg-row align-items-center"
              v-for="(nrAm, index) in NearByAmenities"
              :key="index"
            >
              <div class="custom-control custom-checkbox py-2 mr-3">
                <input
                  class="custom-control-input"
                  type="checkbox"
                  placeholder="0"
                  v-model="nrAm.value"
                  :value="nrAm['id']"
                  :name="'chkboxOption' + nrAm['id']"
                  :id="'chkboxOption' + nrAm['id']"
                />
                <label
                  :for="'chkboxOption' + nrAm['id']"
                  class="d-flex custom-control-label"
                  >{{ nrAm["name"] }}</label
                >
              </div>
              <label v-if="nrAm.value" class="mr-2">Distance:</label>
              <CInput
                v-if="nrAm.value"
                v-model="NearByAmenities[index].dist"
                type="number"
                min="0"
                oninput="this.value = Math.abs(this.value)"
                class="mb-0 w-100 ml-1 validate_number"
              >
                <template #append-content>miles</template>
              </CInput>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div
            @click="openPriceSection = !openPriceSection"
            class="btn btn-dark btn-block dropdown-toggle mt-4 mb-3 d-flex justify-content-between align-items-center"
          >
            Add Price
          </div>
          <div v-if="openPriceSection" class="row">
            <div class="col-12 col-sm-6">
              <div>
                <label class="required-field">Minimum Price</label>
              </div>
              <CInput
                v-model="form.min_price"
                type="number"
                min="0"
                placeholder="Minimum Price"
              />
              <span v-if="$v.form.min_price.$error" class="text-danger"
                >Minimum Price is required</span
              >
            </div>
            <div class="col-12 col-sm-6">
              <div>
                <label class="required-field">Maximum Price</label>
              </div>
              <CInput
                v-model="form.max_price"
                type="number"
                min="0"
                placeholder="Maximum Price"
              />
              <span v-if="$v.form.max_price.$error" class="text-danger"
                >Maximum Price is required</span
              >
            </div>
          </div>
        </div>
      </div>
      <span v-if="validateopenPriceSection" class="text-danger"
        >Min and Max Price is required</span
      >
      <!-- <span v-if="!validateNearByAmenities && validate1" class="text-danger"
            >Nearby Amenities is required</span
        > -->

      <div class="row">
        <div class="col-12">
          <div
            @click="openSectionLayout = !openSectionLayout"
            class="btn btn-dark btn-block dropdown-toggle mt-4 mb-3 d-flex justify-content-between align-items-center"
          >
            Add Layout Designs
          </div>

          <div v-if="openSectionLayout" class="row">
            <div
              class="col-6 d-flex flex-column flex-xl-row align-items-center"
              v-for="(layout, index) in LayoutDesigns"
              :key="index"
            >
              <div class="custom-control custom-checkbox py-2 mr-1">
                <input
                  class="custom-control-input"
                  type="checkbox"
                  placeholder="0"
                  v-model="layout.value"
                  @change="removeLayoutDetails(layout, index)"
                  :value="layout['id']"
                  :name="'chkboxOption2' + layout['id']"
                  :id="'chkboxOption2' + layout['id']"
                />
                <label
                  :for="'chkboxOption2' + layout['id']"
                  class="d-flex custom-control-label"
                  >{{ layout["name"] }}</label
                >
              </div>
              <label v-if="layout.value" class="mr-2">Price:</label>
              <CInput
                v-if="layout.value"
                v-model="LayoutDesigns[index].price"
                type="number"
                min="1"
                oninput="this.value = Math.abs(this.value)"
                class="mb-0 w-100 ml-1 validate_number"
              >
                <template #append-content>$</template>
              </CInput>

              <label v-if="layout.value" class="ml-2">Head Count:</label>
              <CInput
                v-if="layout.value"
                v-model="LayoutDesigns[index].capacity"
                type="number"
                min="1"
                max="10000"
                oninput="this.value = Math.abs(this.value)"
                class="mb-0 w-100 ml-1 validate_number"
              ></CInput>

              <div v-if="layout.value" class="custom-control custom-checkbox py-2 ml-3">
                <input
                  class="custom-control-input"
                  type="checkbox"
                  v-model="layout['is_default']"
                  :name="'layoutIsDefault' + layout['id']"
                  :id="'layoutIsDefault' + layout['id']"
                  @change="setDefault('layout', layout)"
                />
                <label
                  :for="'layoutIsDefault' + layout['id']"
                  class="d-flex custom-control-label"
                  >Is Default</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <span v-if="is_default_not_selected_layout" class="text-danger"
        >Any one Layout Design needs to be set as default.</span
      >

      <div class="row">
        <div class="col-12">
          <div
            @click="openSectionLength = !openSectionLength"
            class="btn btn-dark btn-block dropdown-toggle mt-4 mb-3 d-flex justify-content-between align-items-center"
          >
            Add Contract Length
          </div>

          <div v-if="openSectionLength" class="row">
            <!-- <div
              class="col-6 d-flex flex-column flex-xl-row align-items-center"
              v-for="(cont, index) in ContractLengthsData"
              :key="index"
            >
              <div class="custom-control custom-checkbox py-2 mr-3">
                <input
                  class="custom-control-input"
                  type="checkbox"
                  placeholder="0"
                  v-model="cont.value"
                  :value="cont['id']"
                  :name="'chkboxOption1' + cont['id']"
                  :id="'chkboxOption1' + cont['id']"
                />
                <label
                  :for="'chkboxOption1' + cont['id']"
                  class="d-flex custom-control-label"
                  >{{ cont["length"] | getLength }}</label
                >
              </div>
              <label v-if="cont.value" class="mr-2">Variation in cost:</label>
              <CInput
                v-if="cont.value"
                v-model="ContractLengthsData[index].percent"
                type="number"
                min="0"
                oninput="this.value = Math.abs(this.value)"
                required
                class="mb-0 w-100 ml-1 validate_number"
              >
                <template #append-content>%</template>
              </CInput>

              <div
                v-if="cont.value"
                class="custom-control custom-checkbox py-2 ml-3"
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  v-model="cont['is_default']"
                  :name="'contractLegthIsDefault' + cont['id']"
                  :id="'contractLegthIsDefault' + cont['id']"
                  @change="setDefault('contract', cont)"
                />
                <label
                  :for="'contractLegthIsDefault' + cont['id']"
                  class="d-flex custom-control-label"
                  >Is Default</label
                >
              </div>
            </div> -->
            <div class="col-12 col-sm-6">
              <div>
                <label class="required-field">Minimum Contract Length</label>
              </div>
              <!-- <select class="form-control" v-model="form.min_length">
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
             
              </select> -->
              <CInput
                v-model="form.min_length"
                type="number"
                class="validate_number"
                min="0"
                placeholder="Enter minimum contract length"
              />
              <span v-if="$v.form.min_length.$error" class="text-danger"
                >Minimum Length is required</span
              >
            </div>

            <div class="col-12 col-sm-6">
              <div>
                <label class="required-field">Minimum Contract Length Type</label>
              </div>
              <select class="form-control" v-model="form.contract_type">
                <option value="months" >Months</option>
                <option value="years" >Years</option>
              </select>
            </div>


            <div class="col-12 col-sm-6">
              <div>
                <label class="required-field">Maximum Contract Length</label>
              </div>
              <!-- <select class="form-control" v-model="form.max_length">
              <option value=" "></option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
              </select> -->
              <CInput
                v-model="form.max_length"
                type="number"
                min="0"
                class="validate_number"
                placeholder="Enter maximum contract length"
              />
            </div>
            <div class="col-12 col-sm-6">
              <div>
                <label class="required-field">Maximum Contract Length Type</label>
              </div>
              <select class="form-control" v-model="form.max_contract_length_type">
                <option value="months">Months</option>
                <option value="years">Years</option>
              </select>
            </div>
          </div>

          <!-- <p>Any one layout needs to be set as default.</p> -->
        </div>
      </div>

      <!-- <span v-if="!validateContractLengthsData && validate2" class="text-danger"
        >Contract length is required</span
      > -->

      <div class="row">
        <div class="col-12">
          <div
            @click="openManagerLayout = !openManagerLayout"
            class="btn btn-dark btn-block dropdown-toggle mt-4 mb-3 d-flex justify-content-between align-items-center"
          >
            Add Property Manager
          </div>

          <div v-if="openManagerLayout" class="row">
            <div class="col-12 col-sm-6">
              <div>
                <label class="required-field">Name</label>
              </div>
              <CInput
                v-model="form.manager_name"
                type="text"
                placeholder="Enter Manager Name"
              />
              <span v-if="$v.form.manager_name.$error" class="text-danger"
                >Manager Name is required</span
              >
            </div>
            <div class="col-12 col-sm-6">
              <div>
                <label class="required-field">Email</label>
              </div>
              <CInput
                v-model="form.manager_email"
                type="email"
                placeholder="Enter Manager Email"
              />
              <span v-if="$v.form.manager_email.$error" class="text-danger"
                >Manager Name is required</span
              >
            </div>
            <div class="col-12 col-sm-6">
              <div>
                <label class="required-field">Phone Number</label>
              </div>
              <CInput
                v-model="form.manager_phone_number"
                type="email"
                v-mask="'(###) ###-####'"
                placeholder="Enter Manager Phone Number"
              />
              <span v-if="$v.form.manager_phone_number.$error" class="text-danger"
                >Manager Name is required</span
              >
            </div>
            <div class="col-12 col-sm-6">
              <div>
                <label>Image</label>
              </div>
              <input
                id="managerImage"
                ref="managerImage"
                name="managerImage"
                type="file"
                accept="image/*"
                @change="uploadManagerImage"
              />
              <img
                v-if="form.manager_image"
                :src="`${baseAPI}${form.manager_image}`"
                style="width: 10%"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div
            @click="openSectionAdd = !openSectionAdd"
            class="btn btn-dark btn-block dropdown-toggle mt-4 mb-3 d-flex justify-content-between align-items-center"
          >
            Add Additional Options
          </div>

          <div v-if="openSectionAdd" class="row">
            <div
              class="col-6 d-flex flex-column flex-lg-row align-items-center"
              v-for="(Addop, index) in AddOptions"
              :key="index"
            >
              <div class="custom-control custom-checkbox py-2 mr-3">
                <input
                  class="custom-control-input"
                  type="checkbox"
                  placeholder="0"
                  v-model="Addop.value"
                  :value="Addop['id']"
                  :name="'chkboxOption3' + Addop['id']"
                  :id="'chkboxOption3' + Addop['id']"
                />
                <label
                  :for="'chkboxOption3' + Addop['id']"
                  class="d-flex custom-control-label"
                  >{{ Addop["name"] }}</label
                >
              </div>
              <label v-if="Addop.value" class="mr-2">Price:</label>
              <CInput
                v-if="Addop.value"
                v-model="AddOptions[index].price"
                type="number"
                min="0"
                oninput="this.value = Math.abs(this.value)"
                class="mb-0 w-100 ml-1 validate_number"
              >
                <template #append-content>$</template>
              </CInput>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mt-2 ml-3 required-field">Upload Images</div>
          <div class="align-center top-alignment">
            <div
              class="attachments drop display-inline align-center"
              @dragover.prevent
              @drop="onDrop"
            >
              <div style="margin-top: 31px">
                <p>drag and drop file(s) here from disk</p>
              </div>
              <div class="button-allign">
                <label class="btn btn-primary btn-sm image-middle">
                  Select File
                  <input
                    id="attachments"
                    class="multipleImg"
                    name="attachments"
                    type="file"
                    accept="image/*"
                    @change="uploadFieldChange"
                    multiple
                  />
                </label>
              </div>
            </div>
          </div>
          <span class="text-danger" v-if="$v.attachments.$error">Images is required</span>
          <div class="col-md-12">
            <hr style="position: relative; margin-top: 55px" />
            <div class="col-md-12">
              <div
                class="attachment-holder animated fadeIn"
                v-cloak
                v-for="(attachment, index) in attachments"
                :key="index"
              >
                <div class="row">
                  <div class="col-2">
                    <span class="label label-primary">{{ attachment.name }}</span>
                  </div>
                  <div class="col-10">
                    <button
                      class="btn btn-xs btn-danger"
                      style="float: right"
                      @click.prevent="removeAttachment(attachment)"
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <hr />
              </div>

              <div v-if="id" class="row">
                <div class="col-md-2 mt-2" v-for="(img, imgInx) in images" :key="imgInx">
                  <div
                    class="d-flex align-items-center justify-content-center overflow-hidden border mb-4 rounded position-relative property__img--box"
                  >
                    <img :src="`${baseAPI}${img.image_name}`" class="w-100" />
                    <div class="cross-box">
                      <i class="fa fa-times cross-btn" @click="removeImg(img.id)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="required-field">Upload Thumbnail</div>
          <input
            id="thumbnail"
            name="thumbnail"
            type="file"
            accept="image/*"
            @change="uploadFieldChangeThumbnail"
          />
          <img
            v-if="id && thumbnailImg"
            :src="`${baseAPI}${thumbnailImg}`"
            style="width: 10%"
          />
          <span v-else-if="thumbnail.length > 0">{{ thumbnail[0].name }}</span>
        </div>

        <span v-if="$v.thumbnail.$error" class="text-danger">Thumbnail is required</span>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="required-field">Upload Terms & Condition file</div>
          <input
            id="confFile"
            name="confFile"
            type="file"
            accept="application/pdf"
            @change="uploadFieldChangeFile"
          />
          <button class="btn btn-link" @click="onClick()" v-if="id && id != 'add'">
            <i class="fa fa-file"></i>terms and condition
          </button>
          <span v-else-if="files.length > 0">{{ files[0].name }}</span>
        </div>
        <span v-if="$v.files.$error" class="text-danger">File is required</span>
      </div>
    </div>
    <div class="d-flex justify-content-end submitDiv" v-if="!isSubmitting">
      <span class="spinner" v-if="forBtnLoad">
        <i class="fa fa-circle-o-notch fa-spin"></i>
      </span>
      <!-- <div class="loader" v-if="forBtnLoad"></div> -->
      <CButton
        color="success"
        class="mt-3"
        style="pointer-events: none"
        v-if="isSubmitting"
        disabled
        >Submitting ...
      </CButton>
      <CButton
        @click.prevent="saveModal"
        v-if="!isSubmitting"
        color="success"
        class="mt-3"
        >Save</CButton
      >
      <CButton
        v-if="!isSubmitting"
        @click.prevent="onCancel"
        color="danger"
        class="mt-3 ml-2"
        >Cancel</CButton
      >
    </div>
  </div>
</template>

<script>
import {
  getProperty,
  putProperty,
  postProperty,
  deleteImage,
  getPlaceData,
} from "@/api/properties";
import { getAmenities } from "@/api/amenities";
import { getOptions } from "@/api/options";
import { getLayoutDesigns } from "@/api/layoutDesigns";
import autocomplete1 from "../client/AutoComplete1";
import { getContractLengths } from "@/api/contractLengths";
import { required, requiredIf, maxValue } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { getNearbyAmenities } from "@/api/nearbyAmenities";
import { getToken } from "@/utils/auth";
import axios from "axios";
import { mask } from "vue-the-mask";

import VueSlider from "vue-slider-component";

const config = require("@/config").get(process.env.NODE_ENV);

export default {
  name: "PropertyEditForm",
  directives: { mask },
  components: { Multiselect, autocomplete1, VueSlider },
  data() {
    return {
      showModal: false,
      loading: true,
      forBtnLoad: false,
      isSlider: false,
      is_default_not_selected_layout: false,
      form: {
        location: "",
        area: "",
        capacity: "",
        available_from: "",
        available_end: "",
        price: "",
        floors: "",
        latitude: "",
        longitude: "",
        amenities: "",
        selectedAmenity: [],
        selectedContract: [],
        title: "",
        property_description: "",
        property_class: "A",
        manager_name: "",
        manager_email: "",
        manager_phone_number: "",
        manager_image: "",
        pincode: null,
        state: "",
        country: "",
        min_price: "",
        max_price: "",
        min_length: "2",
        max_length: " ",
        contract_type: "",
        max_contract_length_type: "",
        building_height: "",
        building_size: "",
      },
      value: "",
      options: [],
      errors: null,
      Avalue: null,
      NearByAmenities: [],
      layoutDesigns: [],
      ContractLengthsData: [],
      Amenities: [],
      AddOptions: [],
      LayoutDesigns: [],
      selectedAmenity: [],
      Cvalue: null,
      selectedContract: [],
      Contract: [],
      attachments: [],
      thumbnail: [],
      files: [],
      conditionFile: 0,
      data: "",
      numOfFile: 0,
      id: null,
      images: [],
      openSection: false,
      openSectionLength: false,
      openSectionLayout: false,
      openManagerLayout: false,
      openSectionAdd: false,
      selectedNearbyAmenity: [],
      priceValue: [],
      baseAPI: process.env.VUE_APP_BASE_API,
      thumbnailImg: "",
      managerImage: "",
      file: "",
      temp1: false,
      temp2: false,
      temp3: false,
      latLong: null,
      isChanged: false,
      validateNearByAmenities: "",

      validate1: false,
      validate2: false,
      validate3: false,
      isLocationError: false,
      isSubmitting: false,
      PropertyVideo: "",
      propertyVideoErrorMessage: "",
      property_video_radio: true,
      property_video_url: "",
      openPriceSection: false,
      validateopenPriceSection: "",
    };
  },
  created() {
    this.init();
  },
  mounted() {
    document
      .querySelector(".validate_number")
      .addEventListener("keypress", function (evt) {
        if ((evt.which != 8 && evt.which != 0 && evt.which < 48) || evt.which > 57) {
          evt.preventDefault();
        }
      });
  },
  filters: {
    getLength(data) {
      if (data == 12) {
        return "1 year";
      }
      if (data > 11) {
        return (
          Math.floor(data / 12) +
          " years " +
          (data % 12 > 0 ? (data % 12) + " months" : "")
        );
      } else return data + " months";
    },
  },
  methods: {
    setDefault(entity, val) {},
    init() {
      this.loading = true;
      this.id = this.$router.history.current.params.id;
      let apiQueue = [
        getContractLengths(1, "all"),
        getLayoutDesigns(1, "all"),
        getNearbyAmenities(1, "all"),
        getOptions(1, "all"),
        getAmenities(1, "all"),
      ];

      if (this.id && this.id != "add") {
        apiQueue.push(getProperty(this.id));
      }
      axios
        .all(apiQueue)
        .then(
          axios.spread((contres, layres, nearres, optres, amenres, prores) => {
            // All requests are now complete
            //contract length

            let contractLengthsData = (contres.data && contres.data.data) || [];
            this.options = contractLengthsData;
            if (contractLengthsData) {
              contractLengthsData.map((length) => {
                this.ContractLengthsData.push({
                  length: length.length,
                  id: length.id,
                  value: length.length === 12 ? true : false,
                  price: 0,
                  is_default: length.is_default,
                });
              });
            }

            // layout design
            let layoutDesigns = (layres.data && layres.data.data) || [];
            if (layoutDesigns) {
              layoutDesigns.map((layout) => {
                this.LayoutDesigns.push({
                  name: layout.name,
                  id: layout.id,
                  value: layout.value,
                  price: 0,
                  capacity: 0,
                  is_default:
                    layout.is_default == "0" || layout.is_default == false ? false : true,
                });
              });
              // debugger;
              // console.log("LayoutDesigns ---------hhhh", this.LayoutDesigns);
              // var layout = this.LayoutDesigns.find(
              //   (layout) => layout.is_default == true
              // );
              // if (!layout) this.is_default_not_selected_layout = true;
            }

            //near by amenities

            let nearbyAmenities = (nearres.data && nearres.data.data) || [];
            if (nearbyAmenities) {
              nearbyAmenities.map((amnt) => {
                this.NearByAmenities.push({
                  name: amnt.name,
                  id: amnt.id,
                  value: false,
                  dist: 0,
                });
              });
            }

            //add options

            let addOptions = (optres.data && optres.data.data) || [];
            if (addOptions) {
              addOptions.map((opt) => {
                this.AddOptions.push({
                  name: opt.name,
                  id: opt.id,
                  value: false,
                  price: 0,
                });
              });
            }

            // Amenities details

            let item = (amenres.data && amenres.data.data) || [];
            if (item) {
              item.map((i) => {
                this.Amenities.push({
                  name: i.name,
                  id: i.id,
                });
              });
            }

            //property detail

            if (prores) {
              console.log("PROPERTY DETAILS :::::::", prores);
              this.form = prores.data.data;
              this.form.files = [];
              this.thumbnailImg = prores.data.data.thumbnail_image;
              this.file = prores.data.data.terms_and_condition_file;
              this.images = prores.data.data.images;

              let item = (prores.data && prores.data.data) || [];
              if (this.form.video_link.type == "url") {
                this.property_video_radio = false;
                this.property_video_url = this.form.video_link.url;
              }
              if (item) {
                this.Avalue = item.amenities ? item.amenities : [];
                this.Cvalue = item.contract_lengths ? item.contract_lengths : [];

                this.Avalue.forEach((element) => {
                  this.selectedAmenity.push({
                    id: element.id,
                    name: element.name,
                  });
                });
                this.Cvalue.forEach((element) => {
                  this.selectedContract.push({
                    id: element.id,
                    name: element.name,
                  });
                });
                this.options.forEach((data) => {
                  // if (data.length == prores.data.data.default_contract_length) {
                  //   this.form.default_contract_length = {
                  //     id: data.id,
                  //     length: data.length,
                  //   };
                  // }
                });
                for (var i = 0, len = item.near_by_amenities.length; i < len; i++) {
                  let ItemIndex = -1;
                  ItemIndex = this.NearByAmenities.findIndex(
                    (b) => b.id === item.near_by_amenities[i].near_by_amenity_id
                  );
                  if (ItemIndex >= 0) {
                    this.NearByAmenities[ItemIndex].value = true;
                    this.NearByAmenities[ItemIndex].dist =
                      item.near_by_amenities[i].distance;
                  }
                }

                console.log("contract_lengths =========", item.contract_lengths);
                var contract = item.contract_lengths.find(
                  (contract) => contract.is_default == 1
                );

                for (var j = 0, len = item.contract_lengths.length; j < len; j++) {
                  let ItemIndex1 = -1;

                  ItemIndex1 = this.ContractLengthsData.findIndex(
                    (a) => a.id === item.contract_lengths[j].contract_length_id
                  );
                  if (ItemIndex1 >= 0) {
                    this.ContractLengthsData[ItemIndex1].value = true;
                    this.ContractLengthsData[ItemIndex1].percent =
                      item.contract_lengths[j].percent;
                    this.ContractLengthsData[ItemIndex1].is_default =
                      item.contract_lengths[j].is_default;
                  }
                }

                console.log("layout_designs =========", item.layout_designs);
                var layout = item.layout_designs.find((layout) => layout.is_default == 1);
                // if (!layout) this.is_default_not_selected_layout = true;

                for (var k = 0, len = item.layout_designs.length; k < len; k++) {
                  let ItemIndex2 = -1;

                  ItemIndex2 = this.LayoutDesigns.findIndex(
                    (a) => a.id === item.layout_designs[k].layout_design_id
                  );
                  if (ItemIndex2 >= 0) {
                    this.LayoutDesigns[ItemIndex2].value = true;
                    this.LayoutDesigns[ItemIndex2].price = item.layout_designs[k].price;
                    this.LayoutDesigns[ItemIndex2].capacity =
                      item.layout_designs[k].capacity;
                    this.LayoutDesigns[ItemIndex2].is_default =
                      item.layout_designs[k].is_default;
                  }
                }

                for (var l = 0, len = item.additional_options.length; l < len; l++) {
                  let ItemIndex3 = -1;

                  ItemIndex3 = this.AddOptions.findIndex(
                    (a) => a.id === item.additional_options[l].additional_option_id
                  );
                  if (ItemIndex3 >= 0) {
                    this.AddOptions[ItemIndex3].value = true;
                    this.AddOptions[ItemIndex3].price = item.additional_options[l].price;
                  }
                }
              }
            }
          })
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getLocation(location, flag, placeId) {
      this.isLocationError = false;
      this.getLatLong(placeId);
      this.form.location = location;
    },
    getLatLong(placeId) {
      getPlaceData(placeId).then((response) => {
        setTimeout(() => {}, 10);
        // var flage_postal = false;
        var addresses = response.data.result.address_components;
        // console.log("i am address", response.data);
        var pin_code = addresses.find(
          (compo) => compo["types"].includes("postal_code")
          // flage_postal == true
        );
        // console.log("i am pincode", pin_code);
        this.form.pincode = pin_code && pin_code.long_name ? pin_code.long_name : 0;

        var state = addresses.find((compo) =>
          compo["types"].includes("administrative_area_level_1")
        );
        var country = addresses.find((compo) => compo["types"].includes("country"));
        switch (country.short_name) {
          case "US":
            this.form.country = "USA";
            break;
          default:
            this.form.country = country.long_name;
        }

        // console.log("county",this.form.country)
        this.form.state = state.long_name;
        this.latLong = response.data["result"]["geometry"]["location"];
        // console.log("this is latlong", this.latLong);
        this.isChanged = true;
      });
    },
    removeImg(id) {
      if (confirm("Do you really want to delete Image?")) {
        deleteImage(id, getToken())
          .then((res) => {
            let index = this.images.findIndex((x) => x.id === id);
            this.images.splice(index, 1);
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    onClick() {
      window.open(this.baseAPI + this.file, "_blank");
    },
    uploadFieldChangeThumbnail(e) {
      this.thumbnailImg = "";
      var files = e.target.files;
      if (!files.length) return;
      this.temp1 = true;
      if (!files[0].type.match("image.*")) {
        this.$notify({
          group: "notify",
          type: "error",
          text: "File type not supported",
        });
        return;
      }
      if (this.numOfFile == 1) {
        this.thumbnail = [];
      }
      for (var i = files.length - 1; i >= 0; i--) {
        this.thumbnail.push(files[i]);
        this.numOfFile = 1;
      }
      document.getElementById("thumbnail").value = [];
    },
    uploadManagerImage(e) {
      this.manager_image = "";
      this.managerImage = "";
      var files = e.target.files;
      if (!files.length) return;
      if (!files[0].type.match("image.*")) {
        this.$notify({
          group: "notify",
          type: "error",
          text: "File type not supported",
        });
        return;
      }
      this.managerImage = files[0];
      console.log("files", files[0]);
    },
    uploadPropertyVideo(e) {
      this.PropertyVideo = "";
      var files = e.target.files;
      if (!files.length) return;
      if (!files[0].type.match("video.*")) {
        this.$notify({
          group: "notify",
          type: "error",
          text: "File type not supported",
        });
        return;
      }
      this.PropertyVideo = files[0];
      console.log("files", files[0]);
    },
    onDrop: async function (e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      if (!files.length) return;
      if (!files[0].type.match("image.*")) {
        this.$notify({
          group: "notify",
          type: "error",
          text: "File type not supported",
        });
        return;
      }
      this.temp3 = true;
      for (var i = files.length - 1; i >= 0; i--) {
        this.attachments.push(files[i]);
      }
      document.getElementById("attachments").value = [];
    },
    uploadFieldChange(e) {
      var files = e.target.files;
      if (!files.length) return;
      if (!files[0].type.match("image.*")) {
        this.$notify({
          group: "notify",
          type: "error",
          text: "File type not supported",
        });
        return;
      }
      this.temp3 = true;
      for (var i = files.length - 1; i >= 0; i--) {
        this.attachments.push(files[i]);
      }
      document.getElementById("attachments").value = [];
    },
    removeAttachment(attachment) {
      this.attachments.splice(this.attachments.indexOf(attachment), 1);
    },
    prepareFields() {
      this.data = new FormData();
      if (this.attachments.length > 0) {
        for (var i = 0; i < this.attachments.length; i++) {
          let attachment = this.attachments[i];
          this.data.append("attachments[]", attachment);
        }
      }
    },

    uploadFieldChangeFile(e) {
      var files = e.target.files;
      if (!files.length) return;
      if (!files[0].type.match("application/pdf")) {
        this.$notify({
          group: "notify",
          type: "error",
          text: "Only PDF type supported",
        });
        return;
      }
      this.temp2 = true;
      if (this.conditionFile == 1) {
        this.files = [];
      }
      for (var i = files.length - 1; i >= 0; i--) {
        this.files.push(files[i]);
        this.conditionFile = 1;
      }
      document.getElementById("confFile").value = [];
    },

    onSelectAmenity(value) {
      this.selectedAmenity.push(value);
    },
    removeAmenities(value) {
      let id = this.selectedAmenity.map((amnt) => {
        if (value.id === amnt.id) {
          id = amnt.id;
        }
      });
      let amenitiesData = this.selectedAmenity;
      amenitiesData.splice(id, 1);
      this.selectedAmenity = amenitiesData;
    },

    onSelectContract(value) {
      this.selectedContract.push(value);
    },

    removeContracts(value) {
      let id = this.selectedContract.map((cont) => {
        if (value.id === cont.id) {
          id = cont.id;
        }
      });
      let contractData = this.selectedContract;
      contractData.splice(id, 1);
      this.selectedContract = contractData;
    },
    removeLayoutDetails(layout, index) {
      layout.is_default = false;
      layout.price = 0;
      layout.capacity = 0;
    },
    closeModal() {
      this.showModal = false;
      this.$props.onHideModal();
    },
    onReset() {
      this.form.length = "";
    },
    changeModal() {
      if (!this.showModal) {
        this.onCloseModal();
      }
    },
    onCloseModal() {
      this.showModal = false;
      this.$props.onHideModal();
    },
    saveModal() {
      this.validate1 = true;
      this.validate2 = true;
      this.validate3 = true;
      this.openSectionLength = true;
      this.openManagerLayout = true;
      this.openPriceSection = true;

      this.form.selectedAmenity = this.selectedAmenity;
      this.form.selectedContract = this.selectedContract;

      // this.validateNearByAmenities = this.NearByAmenities.some(
      //     (x) => x.value
      // );
      console.log("ContractLengthsData", this.ContractLengthsData);

      if (this.id && this.id != "add") {
        if (this.thumbnailImg == "") {
          this.temp1 = true;
        }
        if (this.file == "") {
          this.temp2 = true;
        }
        if (this.images.length == 0) {
          this.temp3 = true;
        }
      } else {
        this.temp1 = true;
        this.temp2 = true;
        this.temp3 = true;
      }

      this.$v.$touch();
      console.log("v form", this.$v.form);
      console.log("v error", this.$v.$error);

      // return;
      if (
        this.$v.$error ||
        // !this.validateNearByAmenities ||
        this.isLocationError ||
        this.is_default_not_selected_layout
      ) {
        return;
      }
      this.prepareFields();
      this.data.append("thumbnail", this.thumbnail[0]);
      this.data.append("conditionFile", this.files[0]);
      this.data.append("location", this.form.location);
      this.data.append("price", this.form.price);
      this.data.append("id", this.form.id);
      this.data.append("capacity", this.form.capacity);
      this.data.append("area", this.form.area);
      this.data.append("floors", this.form.floors);
      this.data.append("manager_name", this.form.manager_name);
      this.data.append("min_price", this.form.min_price);
      this.data.append("max_price", this.form.max_price);
      this.data.append("manager_email", this.form.manager_email);
      this.data.append("min_length", this.form.min_length);
      this.data.append("max_length", this.form.max_length);
      this.data.append("manager_phone_number", this.form.manager_phone_number);
      this.data.append("manager_image", this.managerImage);
      this.data.append("contract_type", this.form.contract_type || "months");
      this.data.append(
        "max_contract_length_type",
        this.form.max_contract_length_type || "months"
      );
      this.data.append(
        "building_height",
        this.form.building_height &&
          this.form.building_height.trim() &&
          this.form.building_height.trim() != "" &&
          this.form.building_height.trim() != "null"
          ? this.form.building_height.trim()
          : null
      );
      this.data.append("building_size", this.form.building_size || 0);

      if (this.form.pincode) {
        this.data.append("pincode", this.form.pincode);
      }
      if (this.form.country) {
        this.data.append("country", this.form.country);
      }
      if (this.form.state) {
        this.data.append("state", this.form.state);
      }
      if (!this.property_video_url && this.PropertyVideo) {
        this.data.append("property_video", this.PropertyVideo);
      }

      if (this.property_video_url && !this.PropertyVideo) {
        this.data.append("property_video", this.property_video_url);
      }

      if (!this.property_video_url && !this.PropertyVideo) {
        this.data.append("property_video", null);
      }
      this.data.append("title", this.form.title);
      this.data.append("property_description", this.form.property_description);
      this.data.append("property_class", this.form.property_class);
      if (this.isChanged) {
        console.log("this is change in lat", this.isChanged);
        this.data.append("latitude", this.latLong["lat"]);
        this.data.append("longitude", this.latLong["lng"]);
      }
      // this.data.append(
      //     "default_contract_length",
      //     this.form.default_contract_length.length
      // );
      this.data.append("selectedAmenity", JSON.stringify(this.selectedAmenity));
      this.data.append(
        "selectedContract",
        JSON.stringify(this.ContractLengthsData.filter((val) => val.value))
      );
      this.data.append(
        "nearByAmenities",
        JSON.stringify(this.NearByAmenities.filter((val) => val.value))
      );
      this.data.append(
        "additional_options",
        JSON.stringify(this.AddOptions.filter((val) => val.value))
      );
      this.data.append(
        "layoutDesigns",
        JSON.stringify(this.LayoutDesigns.filter((val) => val.value))
      );
      if (this.files.length > 0) {
        this.data.append("terms_and_condition_file", this.form.terms_and_condition_file);
      }
      this.data.append("available_from", this.form.available_from);
      this.data.append(
        "available_end",
        this.form.available_end &&
          this.form.available_end != "" &&
          this.form.available_end != "null"
          ? this.form.available_end
          : null
      );
      if (this.form.is_available) {
        this.form.is_available = 1;
      } else {
        this.form.is_available = 0;
      }
      this.data.append("is_available", this.form.is_available);
      console.log("this.data==>", this.data, this.form);
      this.isSubmitting = true;
      this.forBtnLoad = true;
      if (this.id && this.id != "add") {
        putProperty(this.id, this.data)
          .then((res) => {
            this.loading = false;
            this.isSubmitting = false;
            // this.forBtnLoad = false;
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
            // this.isSubmitting = false;
            this.$router.push("/admin/properties");
          })
          .catch((error) => {
            this.loading = false;
            this.forBtnLoad = false;
            this.isSubmitting = false;
            console.log("error--------", error.response.data.errors);
            error.response.data.errors.property_video
              ? (this.propertyVideoErrorMessage =
                  error.response.data.errors.property_video[0])
              : null;
            console.log(error, "error");
          });
      } else {
        this.loading = true;
        postProperty(this.data)
          .then((res) => {
            this.loading = false;
            this.forBtnLoad = false;
            // this.$emit("saveProperty", res.data.data);
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
            // this.isSubmitting = false;
            this.$router.push("/admin/properties");
          })
          .catch((error) => {
            this.loading = false;
            this.forBtnLoad = false;
            this.isSubmitting = false;
            error.response.data.errors.property_video
              ? (this.propertyVideoErrorMessage =
                  error.response.data.errors.property_video[0])
              : null;
            console.log(error, "error");
          });
      }
    },
    onCancel() {
      this.$router.push("/admin/properties/");
    },
  },
  validations: {
    form: {
      title: {
        required,
      },
      location: {
        required,
      },
      available_from: {
        required,
      },
      // available_end: {
      //   required,
      // },
      // area: {
      //     required,
      // },
      capacity: {
        required,
        maxValue: maxValue(500),
      },
      // price: {
      //     required,
      // },
      // floors: {
      //     required,
      // },
      // selectedAmenity: {
      //     required,
      // },
      manager_name: {
        required,
      },
      min_price: {
        required,
      },
      max_price: {
        required,
      },
      manager_email: {
        required,
      },
      min_length: {
        required,
      },
      manager_phone_number: {
        required,
      },
      // manager_image: {
      //     required,
      // },
      // property_description: {
      //     required,
      // },
      // selectedContract: {
      //   required
      // },
      // default_contract_length: {
      //     required,
      // },
    },
    attachments: {
      required: requiredIf(function () {
        return this.temp3;
      }),
    },
    files: {
      required: requiredIf(function () {
        console.log(this.temp2, "-0-0");
        return this.temp2;
      }),
    },
    thumbnail: {
      required: requiredIf(function () {
        return this.temp1;
      }),
    },
  },
};
</script>

<style>
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.align-center {
  text-align: center;
}

.top-alignment {
  margin-top: 10px;
}

.drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  height: 100px;
  width: 300px;
  position: relative;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.button-allign {
  text-align: center;
  margin-top: 60px;
}

.image-middle {
  cursor: pointer;
  margin: 0;
}

.multipleImg {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.cross-btn {
  line-height: 2;
}

.cross-box {
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  height: 20px;
  width: 20px;
  border-radius: 50px;
  color: white;
  cursor: pointer;
}

.custom-control {
  min-width: 120px;
}

.c-switch {
  width: 600px;
}

.c-switch-slider {
  width: 40px;
}

.submitDiv {
  margin-bottom: 30px;
}
</style>
